.MuiTab-root {
  color: #939393 !important;
  border-bottom: 1px solid #E3E3E3 !important;
}

.MuiTab-root.Mui-selected {
  font-weight: bold !important;
  color: #585858 !important;
  font-size: 16px;
}
